/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { colors } from '../../customTheme';
import { Helmet } from 'react-helmet-async';
import PATHS from '../../utils/paths';
import useMediaQuery from '@mui/material/useMediaQuery';
import { search } from '../../api/client';
import { Analytics } from 'monolib';
import LoadingButton from '@mui/lab/LoadingButton';
import { formatTotalPrice, formatNumberCompact } from '../../utils/helpers';
import { ItemsGrid } from '../../components/ItemsGrid.tsx';
// import Filters from './Filters.tsx';
import SearchSuggestions from '../../components/SearchSuggestions.tsx';
import LocalCache from '../../utils/LocalCache.ts';
import { calculateSourcePrice } from "../../utils/helpers.js";
import BlurContainer from '../../components/BlurContainer.tsx';
import { useAuth } from '../../contexts/AuthContext';
import { Experimental_CssVarsProvider } from '@mui/material/styles';

import {
  Container,
  Card,
  CardContent,
  Typography,
  Box,
  TextField,
  Slider,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress
} from '@mui/material';

import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Dashboard from '../dashboard/Dashboard.tsx';

const StatTable = ({ title, data, blur }) => {
  return (
    <BlurContainer title={title} blur={blur}>
      <Table aria-label="simple table">
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.x}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.x}
              </TableCell>
              <TableCell align="right">{row.y}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </BlurContainer>
  );
};

const StatCard = ({ title, value }) => {
  return (
    <Card elevation={0} variant="outlined" sx={{ p: 2 }}>
      <Typography
        color="textSeconday"
        variant="h2"
        sx={{ pb: 0.4 }}
        style={{
          fontSize: "1em",
          fontWeight: 400
        }}>
        {title}
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontSize: "1.4em",
          fontWeight: 500
        }}>
        {value}
      </Typography>
    </Card>
  );
}

export const Search = () => {
  const [results, setResults] = useState('');
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [margin, setMargin] = useState(70);
  const [sourcePrice, setSourcePrice] = useState(0);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [showSuggestions, setShowSuggestions] = useState(true);
  const initialSearchDone = useRef(false);
  const searchCache = useRef(new LocalCache());
  const { isPremium } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const performSearch = useCallback(async (searchQuery) => {
    try {
      if (!searchQuery || searchQuery === "") return;
      setLoading(true);
      setError(null);
      setShowSuggestions(false);

      Analytics.instance.track("search_clicked", { query: searchQuery });

      let res = searchCache.current.get(searchQuery);

      if (res === null) {
        res = await search(searchQuery, 1000);
        searchCache.current.set(searchQuery, res);
      }

      setResults(res);
      setQuery(searchQuery);
      setLoading(false);
    } catch (error) {
      Analytics.instance.track("search_error", { error });
      setError("Something went wrong please try again later");
      setLoading(false);
    }
  }, []);

  const onHandleSearchClick = useCallback((searchQuery = query) => {
    performSearch(searchQuery);
    navigate(`?q=${encodeURIComponent(searchQuery)}`, { replace: true });
  }, [query, navigate, performSearch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryParam = searchParams.get('q');
    if (queryParam && !initialSearchDone.current) {
      setQuery(queryParam);
      performSearch(queryParam);
      initialSearchDone.current = true;
    }
  }, [location.search, performSearch]);

  useEffect(() => {
    const source = calculateSourcePrice(margin, results.meanPrice);
    setSourcePrice(source);
  }, [margin, results]);

  const marginDollarAmount = ((results.meanPrice * margin) / 100).toFixed(2);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onHandleSearchClick(query);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    onHandleSearchClick(suggestion);
  };

  const formatDataForTable = (data, title) => {
    const n = 5;

    const sortedData = Object.entries(data).map(([key, value]) => ({ x: key, y: value }))
      .sort((a, b) => b.y - a.y);

    if (sortedData.length > n) {
      const topN = sortedData.slice(0, n);
      const remaining = sortedData.slice(n);

      const otherValue = remaining.reduce((acc, item) => acc + item.y, 0);
      const top3Remaining = remaining.slice(0, 3).map(item => item.x).join(', ');

      topN.push({ x: `Other ${title} (${top3Remaining}...)`, y: otherValue });

      return topN;
    }

    return sortedData;
  };

  const postGridData = useMemo(() => {
    return results?.posts?.slice(0, 32).map(post => ({
      id: post.id,
      title: post.title,
      image: post.picture_url,
      complete: false,
      href: `https://poshmark.com/listing/${post.id}`,
      price: post.price,
    })) ?? [];
  }, [results.posts]);

  const renderMarginSlider = () => {
    return (
      <Card elevation={0} variant="outlined" sx={{ mt: 2 }}>
        <CardContent>
          <Typography variant="body1" style={{ marginBottom: '10px' }}>
            Source at <strong>{formatTotalPrice(sourcePrice)}</strong> for a margin of <strong>{margin}%</strong>. You'll profit <strong>{formatTotalPrice(marginDollarAmount)}</strong>.
          </Typography>
          <Slider
            value={margin}
            onChange={(e, newValue) => setMargin(newValue)}
            aria-labelledby="margin-slider"
            step={1}
            min={0}
            max={100}
            style={{ height: '6px', margin: '0px', padding: '0px' }}
          />
        </CardContent>
      </Card>
    )
  };

  const renderResults = () => {
    if (!results || !results.colorDistribution) return <></>;

    return (
      <Box>
        {renderMarginSlider()}
        <Grid container spacing={1} mt={1}>
          {[
            { title: "Average Time to Sell", value: `${formatNumberCompact(results.averageTimeToSell)} Days` },
            { title: "Average Price", value: `${formatTotalPrice(results.meanPrice)}` },
            { title: "Median Price", value: `${formatTotalPrice(results.medianPrice)}` },
            { title: "Price Range", value: `${formatTotalPrice(results.minPrice)} - ${formatTotalPrice(results.maxPrice)}` }
          ].map((stat, index) => (
            <Grid item xs={6} sm={4} md={3} key={index}>
              <StatCard title={stat.title} value={stat.value} />
            </Grid>
          ))}
        </Grid>
        <Card
          sx={{ mt: 2 }}
          elevation={0}
          variant="outlined">
          <CardContent>
            <Typography
              color="textSeconday"
              variant="h2"
              sx={{ pb: 1 }}
              style={{
                fontSize: "1.2em",
                fontWeight: 400
              }}>
              {`(${formatNumberCompact(results.posts.length)}) Recently Sold Listings`}
            </Typography>
            <ItemsGrid
              items={postGridData ?? []}
              columns={isMobile ? 4 : 8}
            />
          </CardContent>
        </Card>
        <Grid container spacing={2} mt={0}>
          {[
            { title: "Colors", key: "colorDistribution" },
            { title: "Sizes", key: "sizeDistribution" },
            { title: "Style Tags", key: "styleTagDistribution" },
            { title: "Category Features", key: "categoryFeatureDistribution", requiresPremium: true },
            { title: "Categories", key: "categoryDistribution", requiresPremium: true },
            { title: "Departments", key: "departmentDistribution", requiresPremium: true },
            { title: "Condition", key: "conditionDistribution", requiresPremium: true }
          ].map(({ title, key, requiresPremium }) => (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <StatTable
                blur={requiresPremium && isPremium === false}
                title={title}
                data={formatDataForTable(results[key], title)}
              />
            </Grid>
          ))}
        </Grid>
        {/* <Container sx={{ pt: 4 }}>
          <Heatmap posts={results.posts} />
        </Container> */}
      </Box>
    );
  };

  return (
    <Dashboard>
      <Helmet>
        <title>Product Research</title>
        <meta name="description" content={"Find products that sell on Poshmark"} />
        <meta property="og:title" content="Product Research Tool" />
        <meta property="og:description" content="Find The best-selling products on Poshmark." />
        <meta property="og:url" content="https://poshwatch.io/product-research" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={PATHS.PRODUCT_RESEARCH} />
      </Helmet>
      <Experimental_CssVarsProvider>
        <Container mb={2} sx={{ pt: 4 }} maxWidth={false}>
          <Box display="flex" flexDirection='column' alignItems='start'>
            <Typography
              variant="h1"
              style={{
                fontSize: "1.8em",
                fontWeight: 'bold',
                color: colors.mineShaft,
                fontFamily: "Libre Baskerville"
              }}
            >
              Product Research
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
              sx={{ pb: 2, pt: 2 }}
            >
              Find Poshmark's fast-selling products and source with confidence.
            </Typography>
            <TextField
              value={query}
              variant="outlined"
              placeholder="Enter a product description"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px',
                },
              }}
              fullWidth
              disabled={loading}
              error={!!error}
              helperText={error}
              onChange={(e) => {
                setQuery(e.target.value);
                setShowSuggestions(true);
              }}
              onKeyDown={handleKeyDown}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <LoadingButton
                      disabled={!query || query === ""}
                      color="primary"
                      onClick={() => onHandleSearchClick(query)}
                      loading={loading}
                      style={{
                        borderRadius: 44,
                        boxShadow: 'none',
                      }}
                    >
                      <ArrowForwardIcon />
                    </LoadingButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            {showSuggestions && !results && (
              <SearchSuggestions
                onSuggestionClick={handleSuggestionClick}
              />
            )}
          </Box>
          {loading &&
            <Card variant='outlined' sx={{ mt: 1 }}>
              <LinearProgress sx={{ m: 1 }} />
            </Card>
          }
          {!loading && renderResults()}
        </Container>
      </Experimental_CssVarsProvider>
    </Dashboard>
  );
};
